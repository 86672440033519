import axiosInstance from "./Api";

export default {
  async getHomeData() {
    return await axiosInstance().get(`/agent/home-data`);
  },

  async addStudent(payload) {
    return axiosInstance().post(`/agent/add-student`, payload);
  },

  async saveStudentProfile(payload) {
    const { student_user_id } = payload;

    return axiosInstance().post(
      `/agent/save-student-profile/${student_user_id}`,
      payload
    );
  },

  async removeStudentDocument(payload) {
    const { student_user_id, sd_mapper_id } = payload;

    return axiosInstance().post(
      `/agent/remove-student-doc/${student_user_id}/${sd_mapper_id}`
    );
  },

  async removeStudentVisaDocument(payload) {
    const { student_user_id, svd_id } = payload;

    return axiosInstance().post(
      `/agent/remove-student-visa-doc/${student_user_id}/${svd_id}`
    );
  },

  async applyForStudent(payload) {
    const { student_user_id, course_id } = payload;

    return axiosInstance().post(
      `/agent/apply-course-student/${student_user_id}/${course_id}`,
      payload
    );
  },

  async cancelApplicationForStudent(payload) {
    const { student_user_id, course_id } = payload;

    return axiosInstance().post(
      `/agent/cancel-application-student/${student_user_id}/${course_id}`
    );
  },

  async acceptSuggestionForStudent(payload) {
    const { student_user_id, course_id } = payload;

    return axiosInstance().post(
      `/agent/accept-suggestion/${student_user_id}/${course_id}`
    );
  },

  async rejectSuggestionForStudent(payload) {
    const { student_user_id, course_id } = payload;

    return axiosInstance().post(
      `/agent/reject-suggestion/${student_user_id}/${course_id}`
    );
  },

  async getAllStudentSupportList(params) {
    return axiosInstance().get(`/agent/student-support`, { params });
  },

  async getAllStudentsForStudentSupport(params) {
    return axiosInstance().get(`/agent/student-support-students`, { params });
  },

  async getSingleStudentSupport(user_id) {
    return axiosInstance().get(`/agent/student-support/${user_id}`);
  },

  async saveStudentSupport(payload) {
    return axiosInstance().post(`/agent/student-support`, payload);
  },

  async getAreaFranchises(params) {
    return axiosInstance().get(`/agent/area-franchises`, { params });
  },

  async getMicroFranchises(params) {
    return axiosInstance().get(`/agent/micro-franchises`, { params });
  },

  async commissionSwitchAuth(payload) {
    return axiosInstance().post(`/agent/commission-switch-auth`, payload);
  },

  async getFranchiseIndustryNews() {
    return await axiosInstance().get(`/agent/industry-news`);
  },

  async getFranchiseMaterial() {
    return await axiosInstance().get(`/agent/material`);
  },

  async getQuizResult() {
    return await axiosInstance().get(`/agent/training/quiz-result`);
  },

  async getQuizResponses() {
    return await axiosInstance().get(`/agent/quiz-responses`);
  },

  async getAllProducts(params) {
    return await axiosInstance().get(`/agent/shop-products`, {
      params,
    });
  },

  async getSingleProduct(product_id) {
    return await axiosInstance().get(`/agent/shop-products/${product_id}`);
  },

  async getAllOrders(params) {
    return await axiosInstance().get(`/agent/shop-orders`, {
      params,
    });
  },

  async placeShopOrder(payload) {
    return await axiosInstance().post(`/agent/place-shop-order`, payload);
  },

  async makeShopPayUPayment(payload) {
    return await axiosInstance().post(`/agent/shop/payu-payment/make`, payload);
  },

  async verifyShopPayUPayment(transaction_id) {
    return await axiosInstance().get(
      `/agent/shop/payu-payment/verify/${transaction_id}`
    );
  },

  async makeShopStripePayment(payload) {
    return await axiosInstance().post(
      `/agent/shop/stripe-payment/make`,
      payload
    );
  },

  async verifyShopStripePayment(transaction_id) {
    return await axiosInstance().get(
      `/agent/shop/stripe-payment/verify/${transaction_id}`
    );
  },
  async getStudentCounselorChat(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().get(
      `/agent/student-counselor-chat/${student_user_id}`
    );
  },

  async getAllAcademyData(payload) {
    return await axiosInstance().get(`/agent/academy-items/`, {
      params: payload,
    });
  },

  async getFranchiseStatistics(payload) {
    return await axiosInstance().get(`/agent/franchise-statistics/`, {
      params: payload,
    });
  },
  async getServiceRevenue(payload) {
    return await axiosInstance().get(`/agent/service-revenue/`, {
      params: payload,
    });
  },
  async getGST() {
    return await axiosInstance().get(`/agent/gst`);
  },

  async saveGST(payload) {
    return await axiosInstance().post(`/agent/save-gst`, payload);
  },
  async getWorkingInvoice(payload) {
    const { student_user_id } = payload;
    return axiosInstance().get(`/agent/working-invoice/${student_user_id}`);
  },

  async getActiveInvoice(payload) {
    const { student_user_id } = payload;
    return axiosInstance().get(`/agent/active-invoice/${student_user_id}`);
  },

  async updateWorkingInvoice(payload) {
    const { inv_id } = payload;
    return axiosInstance().post(`/agent/save-invoice/${inv_id}`, payload);
  },

  async getCommissionRates(payload) {
    return await axiosInstance().get(`/agent/commission-rates/`, {
      params: payload,
    });
  },

  async addMonthlyExpenses(payload) {
    return await axiosInstance().post(`/agent/save-monthly-expenses`, payload);
  },
  async getMonthlyExpenses(payload) {
    return await axiosInstance().get(`/agent/monthly-expenses`,{
      params: {
        month: payload.month,
        year: payload.year
      },
    });
  },

  async getMonthlyExpensesById(me_id) {
    return await axiosInstance().get(`/agent/monthly-expenses/${me_id}`);
  },
  async getAllMonthlyExpenses(payload) {
    return await axiosInstance().get(`/agent/all-monthly-expenses`,{
      params: payload,
    });
  },

  async addAnnualTarget(payload) {
    return await axiosInstance().post(`/agent/save-annual-target`, payload);
  },
  async approveServiceInvoice(payload) {
    return await axiosInstance().post(`/agent/approve-service-invoice`, payload);
  },

  async pineconeAssistant(payload) {
    return await axiosInstance().post('/agent/pinecone-assistant',payload)
  },
  async getPineconeSession(){
    return await axiosInstance().get('/agent/pinecone-session')
  }
};
